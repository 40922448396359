import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import { MusicCatalogList, MusicCatalogItem } from '../../components/MusicCatalog';
import VerticalChunk from "../../components/VerticalChunk";

const width = 250;

const IndexPage = () => (
  <Layout>
    <Seo title="Music" />
	<h2>Music</h2>
	<div className="main--centerwrapper">
		<StaticImage
		src="../../images/photos/pressRes/_MG_1300.jpg"
		alt="Jesse Rivest music image"
		placeholder="blurred"
		layout="constrained"
		className="main--centered"
		imgClassName="img--bordered-small"
		width={500}
		/>
	</div>
	<h3>Check out some tunes</h3>
    <p>
    	Here's a brief Spotify playlist and—following that—a listing of all jesserivest.com releases;
		click on any one to read more about it.
		Do also check out my <Link to="/collab/">collabs page</Link>!
    </p>
	<VerticalChunk>
		<iframe
		title="Spotify music player"
		src="//open.spotify.com/embed/playlist/0GCwFR0jYsbX6QYq9ZsXAa"
		width="94%"
		height="380"
		frameBorder="0"
		allowtransparency="true"
		allow="encrypted-media"
		className="p"
		/>
	</VerticalChunk>
	<VerticalChunk>
		<MusicCatalogList>
			<MusicCatalogItem
			year={2023}
			month={12}
			category="LP"
			title="Distant December"
			catnum="JRIVEST17"
			width={width}
			url="./17/"
			>
				<Link to="./17/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST17 distant december front.png"
					alt="Jesse Rivest - Distant December - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2023}
			month={11}
			category="single"
			title="Look at What You've Got"
			catnum="JRIVEST16"
			width={width}
			url="./16/"
			>
				<Link to="./16/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST16 look what you got front.jpg"
					alt="Jesse Rivest - Look at What You've Got - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2023}
			month={6}
			category="single"
			title="No Lights Shine Out to Me"
			catnum="JRIVEST15"
			width={width}
			url="./15/"
			>
				<Link to="./15/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST15 no lights front.jpg"
					alt="Jesse Rivest - No Lights Shine Out to Me - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2023}
			month={3}
			category="single"
			title="Close to Me"
			catnum="JRIVEST14"
			width={width}
			url="./14/"
			>
				<Link to="./14/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST14 close front.jpg"
					alt="Jesse Rivest - Close to Me - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2022}
			month={12}
			category="single"
			title="Let it Snow!"
			catnum="JRIVEST13"
			width={width}
			url="./13/"
			>
				<Link to="./13/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST13 Let it Snow! front.jpg"
					alt="Jesse Rivest - Let it Snow! - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2022}
			month={9}
			category="single"
			title="Fans"
			catnum="JRIVEST12"
			width={width}
			url="./12/"
			>
				<Link to="./12/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST12 fans front.jpg"
					alt="Jesse Rivest - Fans - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2022}
			month={6}
			category="single"
			title="Nostargic"
			catnum="JRIVEST11"
			width={width}
			url="./11/"
			>
				<Link to="./11/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST11 nostargic front.jpg"
					alt="Jesse Rivest - Nostargic - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2022}
			month={3}
			category="single"
			title="Blue Skies"
			catnum="JRIVEST10"
			width={width}
			url="./10/"
			>
				<Link to="./10/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST10 blue skies front.jpg"
					alt="Jesse Rivest - Blue Skies - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2022}
			month={1}
			category="single"
			title="Bottle of Wine"
			catnum="JRIVEST09"
			width={width}
			url="./09/"
			>
				<Link to="./09/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST09 bottle of wine front.jpg"
					alt="Jesse Rivest - Bottle of Wine - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2021}
			month={12}
			category="single"
			title="The Christmas Song"
			catnum="JRIVEST08"
			width={width}
			url="./08/"
			>
				<Link to="./08/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST08 christmas song front.jpg"
					alt="Jesse Rivest - The Christmas Song - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2021}
			month={11}
			category="single"
			title="Ruby Soho"
			catnum="JRIVEST07"
			width={width}
			url="./07/"
			>
				<Link to="./07/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST07 ruby soho front.jpg"
					alt="Jesse Rivest - Ruby Soho - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2021}
			month={8}
			category="single"
			title="Summer Grace"
			catnum="JRIVEST06"
			width={width}
			url="./06/"
			>
				<Link to="./06/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST06 summer grace front.png"
					alt="Jesse Rivest - Summer Grace - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2021}
			month={4}
			category="single"
			title="Silent"
			catnum="JRIVEST05"
			width={width}
			url="./05/"
			>
				<Link to="./05/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST05 silent front.png"
					alt="Jesse Rivest - Silent - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2020}
			month={4}
			category="EP"
			title="The D.G.B. EP"
			catnum="JRIVEST04"
			width={width}
			url="./04/"
			>
				<Link to="./04/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST04 dgb ep front.jpg"
					alt="Jesse Rivest - The D.G.B. EP - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2011}
			month={2}
			category="EP"
			title="Everyelsewhere EP"
			catnum="JRIVEST03"
			width={width}
			url="./03"
			>
				<Link to="./03/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST03 everyelsewhere ep front.jpg"
					alt="Jesse Rivest - Everyelsewhere EP - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2010}
			month={3}
			category="LP"
			title="Live at The Mussel Inn—Feb 19, 2010"
			catnum="JRIVEST02"
			width={width}
			url="./02/"
			>
				<Link to="./02/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST02 live at the mussel inn cover.jpg"
					alt="Jesse Rivest - Live at The Mussel Inn—Feb 19, 2010 - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={2006}
			month={4}
			category="LP"
			title="Seventeen Oh-Two Oh-Six"
			catnum="JRIVEST01"
			width={width}
			url="./01/"
			>
				<Link to="./01/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST01 Seventeen Oh-Two Oh-Six front.jpg"
					alt="Jesse Rivest - Seventeen Oh-Two Oh-Six - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
			<MusicCatalogItem
			year={1997}
			month={1}
			category="EP"
			title="The Way Things Were"
			catnum="JRIVEST00"
			width={width}
			url="./00/"
			>
				<Link to="./00/">
					<StaticImage
					src="../../images/catalog artwork/JRIVEST00 way things were front.jpg"
					alt="Jesse Rivest - The Way Things Were - cover art"
					placeholder="blurred"
					layout="constrained"
					width={width}
					imgClassName="img--bordered-small"
					/>
				</Link>
			</MusicCatalogItem>
		</MusicCatalogList>
	</VerticalChunk>

  </Layout>
)

export default IndexPage;
